<template>
  <div class="guideStudy">
    <div class="header">
      <i class="el-icon-alifanhui" @click="back"></i>
      <div>引导训练</div>
      <i class="el-icon-alixuanzhuan"></i>
    </div>
    <div class="videoImg" v-if="videoType">
      <div class="closeVideo" @click="closeVideo">关闭视频</div>
      <video src="../../assets/video/guideVIdeo.mp4" style="width:100%; height:100%" autoplay="autoplay" controls="controls" disablePictureInPicture preload x5-playsinline="" playsinline="" webkit-playsinline=''></video>
    </div>
    <div class="videoTips" v-if="!videoType">
      <div @click="openVideo">
        <i class="el-icon-alibofang"></i>
        <p>开启视频引导</p>
      </div>
    </div>
    <div class="mainBox">
      <van-tabs :active="active" shrink>
        <van-tab title="章节目录" name="a">
          <div class="contentBox"></div>
        </van-tab>
        <van-tab title="操作内容" name="b">
          <div class="contentBox">
            <div class="contentTitle">
              <p>局部麻醉</p>
              <span @click="closeGuide">关闭引导</span>
            </div>
            <ul v-if="guideType">
              <li>1、用2%利多卡因的注射针从所选肋间隙的下位助骨上缘进针。</li>
              <li>2、先做一皮丘，然后自皮肤至胸膜壁层逐县进行局部浸润，麻醉至壁层胸模局部麻醉。</li>
              <li>3、边进针边回抽，判断有无损伤血管。</li>
              <li>4、回抽出胸水即说明进入胸腔，记下进针长度（作为胸腔穿刺深度的参考）并推出针头。</li>
            </ul>
          </div>
        </van-tab>
        <van-tab></van-tab>
      </van-tabs>
      <div class="guideRightBox">
        <div>
          <i class="el-icon-alihoutui"></i>
          <p>上一步</p>
        </div>
        <div>
          <i class="el-icon-aliqianjin"></i>
          <p>下一步</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: "b",
      guideType: true,
      videoType: true,
    }
  },
  methods: {
    // 点击左上角返回
    back() {
      this.$router.push({ name: "home" });
    },
    // 点击关闭视频
    closeVideo() {
      this.videoType = false;
    },
    // 点击开启视频引导
    openVideo() {
      this.videoType = true;
    },
    // 点击关闭引导
    closeGuide() {
      this.guideType = !this.guideType;
    }
  },
  mounted() { },
}
</script>

<style>
.guideStudy {
  position: relative;
  overflow: hidden;
}
.guideStudy .header {
  width: 100%;
  height: 44px;
  line-height: 44px;
  text-align: center;
  color: #101010;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  background: #f8f8f8;
  z-index: 999;
}
.guideStudy .header i {
  margin: 0 18px;
  font-size: 20px;
  display: block;
  width: 30px;
}
.guideStudy .header div:last-child {
  width: 30px;
}
.guideStudy .videoImg {
  width: 100%;
  height: 264px;
  background: #333;
  margin-top: 44px;
  position: relative;
}
.guideStudy .videoImg .closeVideo {
  position: absolute;
  right: 10px;
  top: 5px;
  color: #fff;
  z-index: 999;
}
.guideStudy .videoTips {
  width: 100%;
  height: 264px;
  background: #fff;
  margin-top: 44px;
  position: relative;
  text-align: center;
  padding-top: 30%;
}
.guideStudy .videoTips .el-icon-alibofang {
  color: #0095e2;
  margin: 0 auto;
  font-size: 50px;
}
.guideStudy .videoTips div p {
  color: #0095e2;
  font-weight: bold;
  margin-top: 10px;
}
.guideStudy .van-tabs__line {
  background-color: #0095e2;
}
.guideStudy .van-tab--active {
  color: #0095e2;
}
.guideStudy .van-tab__pane {
  height: calc(100vh - 44px - 264px - 44px);
  overflow: auto;
}
.guideStudy .mainBox {
  position: relative;
}
.guideStudy .guideRightBox {
  width: 33.3%;
  height: 44px;
  background: #fff;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #f9c528;
}
.guideStudy .guideRightBox div {
  text-align: center;
  font-size: 12px;
}
.guideStudy .guideRightBox div:last-child {
  margin-right: 20px;
}
.guideStudy .guideRightBox div i {
  font-size: 18px;
}
.guideStudy .contentBox {
  padding: 12px 30px;
}
.guideStudy .contentBox .contentTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.guideStudy .contentBox .contentTitle p {
  font-size: 16px;
}
.guideStudy .contentBox .contentTitle span {
  color: #0095e2;
}
.guideStudy .contentBox ul {
  margin-top: 20px;
}
.guideStudy .contentBox ul li {
  margin-top: 10px;
  line-height: 24px;
}
</style>